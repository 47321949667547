import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
    width: 100%;
    border-top: 2px solid rgba(99,93,88,.2);
    padding-top: 25px;
    padding-bottom: 25px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    p{
        color: #635D58;
        a{
            font-size: 15px;
            color: #497B1E;
        }
    }
`;

const Footer = () => {
    return (
        <StyledFooter className="container">
            <div className="row justify-content-between">
                <div className="text-center text-sm-left col-12 col-sm-auto mb-2">
                    <p>&#169;Scardor. All Rights Reserved.</p>
                </div>

                <div className="text-center text-sm-left col-12 col-sm-auto">
                    <p>
                        Designed and developed with love by <br className="d-block d-sm-none"/>
                        <a style={{fontSize: '16px'}} className="ml-1" target="_blank" rel="noreferrer" href="https://minimis.studio">Minimis Studio</a>
                    </p>
                </div>
            </div>
        </StyledFooter>
    );
};

export default Footer;
