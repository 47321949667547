import React, { useState } from "react";
import { ATTRIBUTES } from "../assets/appStrings";
import AttributeCard from "../components/attribute-card/AttributeCard";
import SurveyBanner from "../components/survey-banner/SurveyBanner";
import Tierlist from "../components/tier-list/Tierlist";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { move } from "../helpers/move";
import { reorder } from "../helpers/reorder";
import styled from "styled-components";
import client from "../apollo";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { toUrlSafe } from "../helpers/toUrlSafe";

const CREATE_TIERLIST = gql`
    mutation UniqueBoobies($simp: AttributeRating!) {
        createTierList(attributes: $simp) {
            NAME
            KEY_INPUT
            MOBILITY
            MOBBING
            BOSSING
            UTILITY
            SURVIVABILITY
            LEVELING
        }
    }
`;

const StyledAttributeDropZone = styled.div`
    min-height: 213px;
    align-content: space-around;

    @media (min-width: 992px) {
        min-height: 76px;
    }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#F2F2F2",

    // styles we need to apply on draggables
    ...draggableStyle,
});

const Survey = () => {
    const [state, setState] = useState({
        attributes: ATTRIBUTES,
        essential: [],
        important: [],
        useful: [],
        optional: [],
        undesirable: [],
    });

    const [isSpinning, setIsSpinning] = useState(false);

    const [createTierlist] = useMutation(CREATE_TIERLIST, { client });

    const history = useHistory();

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    const id2List = {
        droppable: "attributes",
        droppable1: "essential",
        droppable2: "important",
        droppable3: "useful",
        droppable4: "optional",
        droppable5: "undesirable",
    };

    /**
     * Takes a list's droppable id and returns the list in the state
     * which corresponds to the id.
     */
    const getList = (id) => state[id2List[id]];

    const getListStyle = () => ({
        backgroundColor: "#EBDFC2",
        padding: "10px",
    });

    /**
     * Called whenever the user is finished dropping off the item
     *
     */
    const onDragEnd = (result) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        // If dropped within itself
        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            let newState = { [id2List[source.droppableId]]: items };

            setState({ ...state, newState });
        }

        // If dropped within somewhere else
        else {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            setState({
                ...state,
                [id2List[source.droppableId]]: result[source.droppableId],
                [id2List[destination.droppableId]]:
                    result[destination.droppableId],
            });
        }
    };

    const onResetHandler = () => {
        setState({
            attributes: ATTRIBUTES,
            essential: [],
            important: [],
            useful: [],
            optional: [],
            undesirable: [],
        });
    };

    const onCalculateHandler = () => {
        // 1. Get all the attributes
        let attributeTierPairs = {};

        setIsSpinning(true);

        Object.keys(state)
            .filter(
                (stateKey) =>
                    stateKey !== "attributes" && stateKey !== "newState"
            )
            .forEach((tier) => {
                // console.log("Tier:", tier);
                state[tier].forEach((attr) => {
                    if (attr.name === "LEVELING_SPEED") {
                        attributeTierPairs["LEVELING"] = tier.toUpperCase();
                    } else {
                        attributeTierPairs[attr.name] = tier.toUpperCase();
                    }
                });
            });

        createTierlist({ variables: { simp: attributeTierPairs } }).then(
            ({ data: { createTierList } }) => {
                if (createTierList) {
                    let topChoice = createTierList[0].NAME;

                    history.push(`/results/${toUrlSafe(topChoice)}`, {
                        ...createTierList,
                    });
                }
            }
        );
        // console.log("Attribute Tier Pairs:", attributeTierPairs)
        // 2. Transform to the request

        // 3. Send the request

        // 4. Receive and transform response

        // 5. Send to the next page of the proper character
    };

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <SurveyBanner
                                progress={7 - state.attributes.length}
                                resetClickHandler={onResetHandler}
                                calculateClickHandler={onCalculateHandler}
                                isSpinning={isSpinning}
                            />
                        </div>
                        {/* <div className="col-4">
                        <Footer
                            progress={7 - state.attributes.length}
                            resetClickHandler={onResetHandler}
                            calculateClickHandler={onCalculateHandler}
                        ></Footer>
                    </div> */}
                    </div>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <StyledAttributeDropZone
                                ref={provided.innerRef}
                                className="row mt-5 mb-3 px-sm-0 px-0"
                                style={getListStyle()}
                            >
                                {state.attributes.map((attribute, index) => (
                                    <Draggable
                                        key={`${attribute.name}-key`}
                                        draggableId={`${attribute.name}-id`}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <AttributeCard
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                        .style
                                                )}
                                                isDragging={snapshot.isDragging}
                                                name={attribute.name}
                                                description={
                                                    attribute.description
                                                }
                                                className="ml-2"
                                                color={attribute.color}
                                                assigned={false}
                                            ></AttributeCard>
                                        )}
                                    </Draggable>
                                ))}
                            </StyledAttributeDropZone>
                        )}
                    </Droppable>

                    {/* Divider Line */}
                    {/* <div className="row">
                    <div
                        className="col-12"
                        style={{
                            backgroundColor: "#333333",
                            width: "100%",
                            height: " 3px",
                            borderRadius: "14px",
                        }}
                    ></div>
                </div> */}
                </div>
                <Tierlist {...state}></Tierlist>
            </DragDropContext>
        </div>
    );
};

export default Survey;
