export const ATTRIBUTES = [
    {
        name: "KEY_INPUT",
        description:
            "Extent to which higher key input is necessary to play more efficiently",
        color: "#ffffff",
    },
    {
        name: "MOBILITY",
        description:
            "Extent to which your skills can functionally move you in a mobbing/bossing situation",
        color: "#ffffff",
    },
    {
        name: "MOBBING",
        description:
            "Extent to which your skills can clear mobs while training quickly (incl. Kishin)",
        color: "#ffffff",
    },
    {
        name: "BOSSING",
        description:
            "Extent to which your skills can clear bosses quickly or dependably",
        color: "#ffffff",
    },
    {
        name: "UTILITY",
        description:
            "Extent to which your skills buff teammates' clearing or surviving capabilities",
        color: "#ffffff",
    },
    {
        name: "SURVIVABILITY",
        description:
            "Extent to which your skills/HP/Def. increase your ability to survive",
        color: "#ffffff",
    },
    {
        name: "LEVELING_SPEED",
        description:
            "Higher number means faster possible leveling speed (includes Kishin)",
        color: "#ffffff",
    },
];

export const POINTS = [
    { name: "UNDESIRABLE", color: "black", backgroundColor: "#F2F2F2", index:50 },
    { name: "OPTIONAL", color: "white", backgroundColor: "#1A91FF", index: 4 },
    { name: "USEFUL", color: "white", backgroundColor: "#9B4CFF", index: 3 },
    { name: "IMPORTANT", color: "black", backgroundColor: "#FFCD4C", index: 2 },
    { name: "ESSENTIAL", color: "white", backgroundColor: "#00B33D", index: 1 },
];
