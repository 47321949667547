import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledContainer = styled.div`
    padding-top: 230px;
`;
const StyledIntroHeader = styled.h1`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;

    @media (min-width: 768px){
        font-size: 36px;
    }
`;

const StyledIntroText = styled.p`
    color: #635D58;
    margin-bottom: 30px;
    font-size: 16px;

    @media (min-width: 768px){
        font-size: 20px
    }
`;

const StyledIntroButton = styled.button`
    display: block;
    /* width: 100%; */
    color: #FFFFFF;
    border-radius: 5px;
    font-weight: bold;
    padding: 13px 25px;
    background-color: #497B1E;
    line-height: 19px;
    border: none;
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 135px;
`;

const IntroBanner = () => {
    return (
        <StyledContainer className="container">
            <div className="row text-center justify-content-center">
                <div className="col-12 col-md-5">
                    <StyledIntroHeader>Maple Character Picker</StyledIntroHeader>
                    <StyledIntroText>
                        Our weighted pointing algorithm allows us to help you pick the best class suitable for your play-style.
                    </StyledIntroText>
                    <Link to="/survey">
                        <StyledIntroButton>
                            Find Your Class
                        </StyledIntroButton>
                    </Link>
                </div>
            </div>
        </StyledContainer>
    );
};

export default IntroBanner;
