export const jobClasses = [
    {
        name: "Battlemage",
        description: "The Battle Mage specializes in fast, close-range combat that overpowers foes. The Battle Mage uses staffs to decimate multiple enemies with flashy attack spells and casts auras to aid the party a character is in.",
        attribute: "INT",
        skillOne: "Finishing Blow",
        skillTwo: "Battle Burst",
        skillThree: "Battle Master",
        skillOneDescription: "Quickly smack enemies 6 times, with a chance to stun",
        skillTwoDescription: "Increases the number of attacks for blow skills and reduces the amount of souls required for Condemnation",
        skillThreeDescription: "Permanently increase your damage and minimum critical damage.",
        skillOneImage: "https://static.wikia.nocookie.net/maplestory/images/b/b9/Skill_Finishing_Blow.png",
        skillTwoImage: "https://static.wikia.nocookie.net/maplestory/images/0/05/Skill_Battle_Burst.png",
        skillThreeImage: "https://static.wikia.nocookie.net/maplestory/images/2/2d/Skill_Battle_Mastery.png",
        weapon: "STAFF",
        background: "https://nxcache.nexon.net/cms/2019/Q3/2147/msw-4241-190725-class-thumb-battle-mage.jpg",
        sprite: "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Legends_Evan_V2.jpg"
    },
    {
        name: "Evan",
        description:
            "Evan's playstyle is unique. Evan has his own set of attacks to use against enemies, alongside his dragon Mir. Using two specific attacks together, one of Mir's and one of Evan's, results in a Fusion Attack, where Mir uses Evan's attack to deal extra damage on enemies.",
        attribute: "INT",
        skillOne: "Mana Burst",
        skillTwo: "Earth Circle",
        skillThree: "Wind Circle",
        skillOneDescription:
            "Four bursts of highly condensed mana attack enemies in front, twice in a row.",
        skillTwoDescription:
            "Forms an irregular pentacle that raises the ground and damages enemies.",
        skillThreeDescription:
            "Launches a gust of wind to push enemies aside and deal damage. Can be resisted by some monsters.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/16/Skill_Mana_Burst_IV.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/4/4a/Skill_Earth_Circle.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/5/51/Skill_Wind_Circle.png",
        weapon: "WAND OR STAFF",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2155/190725_evan_jobclass.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Legends_Evan_V2.jpg",
    },
    {
        name: "Blaze Wizard",
        description:
            "The magician job of the Cygnus Knights, Blaze Wizards wield wild and potent fire magic. Like other Cygnus Knights, they are able to call forth the Elements to help them in battle. Many of the Blaze Wizard's skills are imbued with fire elemental power, allowing the player to hit harder against fire weak monsters, but be forced to give it their all or call in help against monsters resistant to fire.",
        attribute: "INT",
        skillOne: "Orbital Flame",
        skillTwo: "Blazing Extinction",
        skillThree: "Ignition",
        skillOneDescription:
            "Fly forward and project flame onto enemies around you",
        skillTwoDescription:
            "Launches an intense flame sphere that burns nearby enemies with seeking chains of fire.",
        skillThreeDescription:
            "Empower your flames to cause all skills to create and spread additional damage over time.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/cc/Skill_Orbital_Flame.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/0/0f/Skill_Blazing_Extinction.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/78/Skill_Ignition.png",
        weapon: "WAND OR STAFF",
        background:
            "https://nxcache.nexon.net/umbraco/2543/blazewiz_header_web.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_CygnusKnights_FlameWizard_BlazeWizard.png",
    },
    {
        name: "Archmage (I/L)",
        description:
            "The Magician (Ice, Lightning) job branch combines the elements of Ice to freeze / slow down enemies and Lightning to shock enemies. Ice skills can slow down the enemy, with a maximum of 5 stacks; the more stacks, the higher the damage boost for Lightning skills",
        attribute: "INT",
        skillOne: "Chain Lightning",
        skillTwo: "Frozen Orb",
        skillThree: "Lightning Orb",
        skillOneDescription:
            "Attacks target with high-voltage lightning attack, stunning the enemy. Additional critical rate is applied, and Chain Lightning can chain attack targets near the target.",
        skillTwoDescription:
            "Create and throw a Frozen Orb that fires ice shards everywhere to deal damage. The orb slows significantly when it encounters an enemy. Unaffected by attack reflection.",
        skillThreeDescription:
            "Creates a large, unstable sphere of electricity to continuously zap enemies. Unaffected by attack reflection.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/f/ff/Skill_Chain_Lightning.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/eb/Skill_Frozen_Orb.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/20/Skill_Lightning_Orb.png",
        weapon: "WAND OR STAFF",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2165/190725_magician_jobclass.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Explorer_Magician.png",
    },
    {
        name: "Beast Tamer",
        description:
            "Chase uses skills in conjunction with four animals - a bear, leopard, hawk and cat, all of which are cycled through Critter Select. The bear uses mainly strong melee skills, the leopard uses fast attacks over wide areas, the hawk uses aerial skills and provides many enhancements to speed, and the cat uses a variety of buffs.",
        attribute: "INT",
        skillOne: "Paw Swipe",
        skillTwo: "Lil' Fort",
        skillThree: "Fishy Slap",
        skillOneDescription: "Attacks several enemies 3 times.",
        skillTwoDescription: "Summons a toy bear to just go nuts on the enemy.",
        skillThreeDescription:
            "Hold down the skill key to smack enemies with a fish.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/21/Skill_Paw_Swipe.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/9/9d/Skill_Li%27l_Fort.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e9/Skill_Fishy_Slap.png",
        weapon: "SCEPTER",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2149/190725_beasttamer_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/8/8b/ClassArtwork_Beast_Tamer_%28RED%29.png",
    },
    {
        name: "Luminous",
        description:
            "Luminous uses a balance of Light and Dark magic. This job uses a light/dark skill system whereby frequently using light magic skills fills your orb with darkness whereas frequently using dark magic skills fills with light. Once you fully charge up either light/dark, you will go into a state of Equilibrium for 7 seconds that allows you to activate powerful skills",
        attribute: "INT",
        skillOne: "Reflection",
        skillTwo: "Apocalypse",
        skillThree: "Ender",
        skillOneDescription:
            "Emit a powerful arrow of light that splits when it hits enemies to deal splash damage.",
        skillTwoDescription:
            "Opens the Abyssal Rift under enemies to flood them with void energy.",
        skillThreeDescription:
            "Attacks enemies with a giant blade that deals great damage.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e5/Skill_Reflection.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/2c/Skill_Apocalypse.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/4/43/Skill_Ender.png",
        weapon: "SHINING ROD",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2163/msw-4247-190725-luminous-class-page.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Legends_Luminous_V2.jpg",
    },
    {
        name: "Kanna",
        description:
            "With a Fan in her hand and her spirit fox Haku at her side, she calls on the strength of spirits to attack at her will.",
        attribute: "INT",
        skillOne: "Shikigami Haunting",
        skillTwo: "Tengu Strike",
        skillThree: "Ghost Yaksha Boss",
        skillOneDescription:
            "Use your power as a Spirit Walker to attack enemies in front of you",
        skillTwoDescription:
            "Summons twin Tengu to simultaneously attack enemies to the right and left.\nUse the skill again while the Tengu are summoned to have them return to Kanna and attack again with a 100% critical.",
        skillThreeDescription:
            "Summons the mighty Ghost Yaksha Boss and command it to attack and push back enemies",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/71/Skill_Shikigami_Haunting_4.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/7a/Skill_Tengu_Strike.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/4/4e/Skill_Ghost_Yaksha_Boss.png",
        weapon: "FAN",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2160/190725_kanna_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/1/1a/ClassArtwork_Kanna_%28Female_1%29.png",
    },
    {
        name: "Bishop",
        description:
            "The Bishop job branch specializes in Holy elemental skills as well as party support",
        attribute: "INT",
        skillOne: "Angel Ray",
        skillTwo: "Big Bang",
        skillThree: "Bahamut",
        skillOneDescription:
            "Attacks with a holy arrow, dealing tremendous holy damage. Very effective against Undead or Devil monsters. Restores party members' HP. Restores Undead party members as well.",
        skillTwoDescription:
            "Gather power to form an energy vortex, culminating in a powerful Holy explosion.",
        skillThreeDescription:
            "Temporarily summons Bahamut to deal Holy damage. Bahamut can attack multiple enemies at the same time, and being attacked by Bahamut will make them extra vulnerable to Holy attacks. This skill is unaffected by enemy damage reflection skills.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/a/a5/Skill_Angel_Ray.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/c4/Skill_Big_Bang.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/4/40/Skill_Bahamut.png",
        weapon: "WAND OR STAFF",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2165/190725_magician_jobclass.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Explorer_Magician.png",
    },
    {
        name: "Archmage (F/P)",
        description:
            "The Magician (Fire, Poison) job branch combines the elements of Fire and Poison to apply DoT (damage over time) to their enemies, while gaining additional damage as they maintain multiple sources of DoT.",
        attribute: "INT",
        skillOne: "Paralyze",
        skillTwo: "Flame Haze",
        skillThree: "Mist Eruption",
        skillOneDescription:
            "Surrounds multiple enemies with fire to temporarily paralyze them while inflicting heavy damage. Paralyzed enemies take continuous damage. Can be resisted by some monsters.",
        skillTwoDescription:
            "Surrounds enemies with burning poisonous mist to make them untouchable while slowing them down with continuous damage. In addition, if it hits an enemy, the Poison Mist will form at that location. If it does not hit an enemy, the Poison Mist will form at your location.",
        skillThreeDescription:
            "Permanently increases Poison Mist's continuous damage. Upon using the skill, nearby Poison Mists explode, dealing fatal damage to enemies. The damage will increase proportional to the number of continuous damage effects applied to the target. You cannot detonate Mists set by others.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/19/Skill_Paralyze.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/3b/Skill_Flame_Haze.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e5/Skill_Mist_Eruption.png",
        weapon: "WAND OR STAFF",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2165/190725_magician_jobclass.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Explorer_Magician.png",
    },
    {
        name: "Kinesis",
        description:
            "Employs the power of telekinesis (the ability to move physical objects with the power of the mind) rather than utilizing magic.",
        attribute: "INT",
        skillOne: "Psychic Grab",
        skillTwo: "B.P.M",
        skillThree: "Metal Press",
        skillOneDescription:
            "Lift up monsters or objects with the powers of psychokinesis. You cannot lift bosses or enemies 11 levels higher than you. The following actions can be done while holding.",
        skillTwoDescription:
            "Pummel your foes with the ultimate combination of block-rocking blows. Unaffected by enemy damage reflection skills.",
        skillThreeDescription:
            "Lifts a huge metal object and drops it on enemies in front of you.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/2d/Skill_Psychic_Grab.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/5/58/Skill_Ultimate_-_B.P.M..png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/0/0e/Skill_Ultimate_-_Metal_Press.png",
        weapon: "PSY LIMITER",
        background:
            "https://nxcache.nexon.net/umbraco/3735/ms_kinesis_header_bg.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Kinesis.png",
    },
    {
        name: "Illium",
        description:
            "A genius when it comes to working with machines. Illium’s ancinet Crystal resonates with magic abilities that enhances allies and deals enormous damage to enemies. When the Crystal is filled up with magic, Illium can use it to temporarily use powerful skills and fly freely",
        attribute: "INT",
        skillOne: "Radiant Javelin",
        skillTwo: "Domination / Reaction",
        skillThree: "Destruction",
        skillOneDescription:
            "Conjures a magical javelin that dashes back and forth between enemies.",
        skillTwoDescription:
            "Triggers when your crystal comes into contact with a Radiant Orb. Strikes nearby enemies, marking them with an Umbral Brand.",
        skillThreeDescription:
            "Triggers when your crystal comes into contact with Radiant Javelin. Releases a destructive blast that strikes nearby enemies.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/7e/Skill_Radiant_Javelin.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/a/a1/Skill_Reaction_-_Domination.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/9/9a/Skill_Reaction_-_Destruction.png",
        weapon: "LUCENT GAUNTLET",
        background:
            "https://nxcache.nexon.net/cms/2017/2115/ill171220bgpdsjhg4.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/4/44/ClassArtwork_Illium.png",
    },
    {
        name: "Dual Blade",
        description:
            "Dual Blades move in a flash across battlefield while wielding two weapons: a dagger in one hand and a katara in the other. Their skills grant them high evasion, the ability to sneak past foes, mobility and the ability to link special combos.",
        attribute: "LUK",
        skillOne: "Phantom Blow",
        skillTwo: "Blade Fury",
        skillThree: "Asura's Anger",
        skillOneDescription:
            "The secret technique of raging Secret Garden. Deals a flurry of piercing attacks against one enemy. Ignores a portion of the enemy's defense.",
        skillTwoDescription:
            "The secret behind Secret Garden's tornado. Spin furiously with your two blades to damage nearby enemies. Ignores a portion of the enemy's defense. Mastery Book required to unlock Lv.11",
        skillThreeDescription:
            "Creates a vortex of wind blades, damaging nearby enemies. Unaffected by attack reflection.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/d/de/Skill_Phantom_Blow.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/33/Skill_Blade_Fury.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/5/5c/Skill_Asura%27s_Anger.png",
        weapon: "DAGGER PAIRED WITH A KATARA",
        background:
            "https://nxcache.nexon.net/umbraco/2539/dualblade_header_web.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_ExplorerAdventurer_DualBlade.png",
    },
    {
        name: "Night Walker",
        description:
            "Night Walkers, like the Night Lord Explorer Thief, can throw stars at the enemy to damage them, albeit imbued with the element of Darkness. The Shadow Bat skill also aids Night Walkers when attacking mobs, summoning bats that attack enemies alongside you.",
        attribute: "LUK",
        skillOne: "Quintuple Star",
        skillTwo: "Shadow Spark",
        skillThree: "Shadoow bat",
        skillOneDescription:
            "Throws 5 Throwing Stars in a row at the target. The last attack uses the power of darkness to deal greater damage.",
        skillTwoDescription:
            "Throws penetrating Throwing Stars imbued with the power of darkness. At max range the stars explode, dealing extra damage to all enemies in range.",
        skillThreeDescription:
            "Summons a Shadow Bat which sometimes attacks with you. The attack rate will double for enemies marked with Darkness.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/8/86/Skill_Quintuple_Star.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/31/Skill_Shadow_Spark.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/be/Skill_Shadow_Bat.png",
        weapon: "CLAW (FOR THROWING STARS)",
        background:
            "https://nxcache.nexon.net/umbraco/2531/nightwalker_header_web.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_CygnusKnights_NightWalker.png",
    },
    {
        name: "Phantom",
        description:
            "Using a Cane as a primary weapon and a Card as a secondary weapon, Phantom has the unique ability to copy skills from Explorers",
        attribute: "LUK",
        skillOne: "Mille Aguilles",
        skillTwo: "Tempest",
        skillThree: "Carte Noir",
        skillOneDescription:
            "Fly into a stabbing frenzy, jabbing your enemies with blinding speed. This attack can be executed repeatedly.",
        skillTwoDescription:
            "Creates an expanding tornado of cards to blast through enemies. Always does critical hits, and allows you to move while using the skill, but Speed decreases as the tornado of cards increases in size.",
        skillThreeDescription:
            "Each critical attack has a chance to produce a card that attacks nearby enemies automatically. This card is added to your deck.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/a/a2/Skill_Mille_Aiguilles.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/39/Skill_Tempest.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/3e/Skill_Carte_Noir.png",
        weapon: "CANE",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2171/190725_phantom_jobclass.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Legends_Phantom_V2.jpg",
    },
    {
        name: "Shadower",
        description:
            "The Shadower job branch, unlike Night Lords, uses Daggers as their primary weapon, and Dagger Scabbards or Shields as secondary weapons. Instead of attacking from afar, Shadowers focus on fighting monsters up close",
        attribute: "LUK",
        skillOne: "Assassinate",
        skillTwo: "Shadow Veil",
        skillThree: "Sudden Raid",
        skillOneDescription:
            "Attacks an enemy's vital points over and over to deal tremendous damage. Cast the Assassinate skill once more after the first strike to deal a finishing blow.",
        skillTwoDescription:
            "Summons a dark ally to deal damage over time. Chance of Advanced Dark Sight increased within Shadow Veil's attack range.",
        skillThreeDescription:
            "Calls in a horde of friends to set the stage on fire.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/19/Skill_Assassinate.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/c4/Skill_Shadow_Veil.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/c9/Skill_Sudden_Raid.png",
        weapon: "DAGGER",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2176/190725_thief_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/2/2d/ClassArtwork_Shadower_%28MapleStory_M%29.png",
    },
    {
        name: "Night Lord",
        description:
            "The Night Lord job branch uses Throwing Stars alongside their primary weapon, Claws, and Charms as secondary weapons. They throw stars at enemies from afar to deal damage.",
        attribute: "LUK",
        skillOne: "Quadruple Throw",
        skillTwo: "Assassin's Mark",
        skillThree: "Showdown",
        skillOneDescription: "Masterfully unleash four throwing stars.",
        skillTwoDescription:
            "While attacking with this skill, you have a chance to leave an assassin's mark on the enemy. The next attack on a marked enemy will cause throwing stars to form on that enemy and attack other enemies nearby. Throwing stars that hit enemies with attack reflection will not damage you.",
        skillThreeDescription:
            "Attacks up to 6 enemies and provokes them, making them untouchable. Provoked enemies grant more EXP and items. Bosses suffer half of the effect.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/74/Skill_Quad_Star_%28Night_Lord%29.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/0/07/Skill_Assassin%27s_Mark.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/d/da/Skill_Showdown.png",
        weapon: "CLAW (FOR THROWING STARS)",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2176/190725_thief_jobclass.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Explorer_Thief.png",
    },
    {
        name: "Xenon",
        description:
            "With a Whip Blade and a Core Controller, Xenon uses technology and quick-paced attacks to eliminate his enemies",
        attribute: "STR",
        skillOne: "Mecha Purge",
        skillTwo: "Beam Dance",
        skillThree: "Hypogram",
        skillOneDescription:
            "Roo-D scans for a critical weakness, then attacks with a high-tech sniper rifle to inflict severe damage to a single target. Change mode with Modal Shift.",
        skillTwoDescription:
            "Unleash a flurry with your Whip Blade to damage nearby enemies.",
        skillThreeDescription:
            "Forms a Hypogram Field and they assaults enemies each time the orb of energy hits the ground. The energy orb is not affected by attack reflection. Change mode with Modal Shift.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/22/Skill_Mecha_Purge_Snipe.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/6/60/Skill_Beam_Dance.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e0/Skill_Hypogram_Field_Force_Field.png",
        weapon: "WHIP BLADE",
        background:
            "https://nxcache.nexon.net/umbraco/2518/xenon_header_web.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/6/6d/ClassArtwork_Xenon_%28Male%29.png",
    },
    {
        name: "Cadena",
        description:
            "Uses a Chain as her primary weapon and a Warp Forge as her secondary weapon. Specializes in combo attacks to enable insane mobility and mobbing.",
        attribute: "LUK",
        skillOne: "Chain Arts: Thrash",
        skillTwo: "Summon Brick",
        skillThree: "Summon Scimitar",
        skillOneDescription:
            "Press the Attack Key to whip your chain forward, then bring it crashing down. Enemies hit by the 2nd strike are pushed back. Foes struck by the chain are slowed for a short time.",
        skillTwoDescription:
            "Summons a huge brick and jumps forward, using your chains to bring it crashing down. Jumps farther when used while pressing the up arrow key.",
        skillThreeDescription:
            "Summons a scimitar to instantly cut through enemies. Usable while airborne and with other skills.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/1d/Skill_Chain_Arts_Thrash.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/5/56/Skill_Summon_Brick.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/f/f7/Skill_Summon_Scimitar.png",
        weapon: "CHAIN",
        background:
            "https://nxcache.nexon.net/cms/2017/1925/171129cdajbpgncb17e.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Nova_Cadena.jpg",
    },
    {
        name: "Hoyoung",
        description:
            "Uses ritual fans to manage 3 elements to create powerful seals and scrolls.",
        attribute: "LUK",
        skillOne: "Thousand-Ton Stone",
        skillTwo: "Butterfly Dream",
        skillThree: "Consuming Flames",
        skillOneDescription:
            "Transforms into a thousand-ton stone and drops down from the sky to crush enemies on the ground. Can only be used while airborne.",
        skillTwoDescription:
            "Summons a swarm of colorful but deadly flowers. While this skill is active, your attacks summon butterflies to attack your enemies.",
        skillThreeDescription:
            "Fans the flames of destruction. Can only be used while airborne.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e9/Skill_Thousand-Ton_Stone.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/8/80/Skill_Scroll_Butterfly_Dream.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/1e/Skill_Heaven_Consuming_Flames.png",
        weapon: "RITUAL FAN",
        background:
            "https://nxcache.nexon.net/cms/2019/q4/1985/191120_thief_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/6/60/ClassArtwork_Hoyoung.png",
    },
    {
        name: "Aran",
        description:
            "Aran uses many ice and combination attacks against her foes with a Polearm, while using a Mass as the secondary weapon.",
        attribute: "STR",
        skillOne: "Beyond Blade",
        skillTwo: "Final Blow",
        skillThree: "Judgement Draw",
        skillOneDescription:
            "Calls upon all of Aran's might to sweep enemies away with Maha. Shoots out shockwaves in front of you and behind you.",
        skillTwoDescription:
            "Swing your polearm around to deal massive damage, ignoring some DEF.",
        skillThreeDescription:
            "Your polearm slams into the ground with great force, bouncing nearby enemies. Enemies hit directly by the polearm will be frozen and take damage over time.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/19/Skill_Beyond_Blade.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/cc/Skill_Final_Blow.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/1d/Skill_Judgment_Draw_%28Aran%29.png",
        weapon: "POLEARM",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2145/msw-4245-190725-aran-class-page.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Legends_Aran_V2.jpg",
    },
    {
        name: "Adele",
        description:
            "Adele is a powerful warrior who uses her mystical Aether to summon powerful blades that strike out against her foes.",
        attribute: "STR",
        skillOne: "Cleave",
        skillTwo: "Reign of Destruction",
        skillThree: "Hunting Decree",
        skillOneDescription:
            " Creates a massive sword to cut enemies ahead of you.",
        skillTwoDescription:
            "Summons countless swords across a large area. Unaffected by attack reflection.",
        skillThreeDescription:
            "Issues a command to aether swords created with Aether Forge to pursue the enemy. Can only be used when there are aether swords present.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/2c/Skill_Cleave.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/10/Skill_Reign_of_Destruction.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/7b/Skill_Hunting_Decree.png",
        weapon: "BLADECASTER",
        background:
            "https://nxcache.nexon.net/cms/2020/q2/2022/200616_adele_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/7/72/ClassArtwork_Adele.png",
    },
    {
        name: "Blaster",
        description:
            "They use a special two-handed weapon, the Arm Cannon, as their primary weapon while using Charges as their secondary.",
        attribute: "STR",
        skillOne: "Magnum Punch",
        skillTwo: "Double Blast",
        skillThree: "Revolving Cannon",
        skillOneDescription:
            "With the explosive power of the Arm Cannon, unleash a powerful straight punch in front of you.",
        skillTwoDescription:
            "Using the momentum from your Arm Cannon, perform a powerful 2-hit combo in the direction you're facing.",
        skillThreeDescription:
            "Use your Arm Cannon to create additional explosions and deal damage. This cannot be used as a standalone skill.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/f/f6/Skill_Magnum_Punch.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/f/f9/Skill_Double_Blast_%28Blaster%29.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/cf/Skill_Revolving_Cannon.png",
        weapon: "ARM CANNON",
        background: "https://nxcache.nexon.net/umbraco/6365/blstrbg2554.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Resistance_Blaster.jpg",
    },
    {
        name: "Dawn Warrior",
        description:
            "With their massive strength and powerful physique, Dawn Warriors (or Soul Masters) vanquish the enemy in close proximity. They are accompanied by Sol, the spirit of light, especially in combat",
        attribute: "STR",
        skillOne: "Moon Dancer",
        skillTwo: "Speeding Sunset",
        skillThree: "Styx Crossing",
        skillOneDescription:
            "Empowers your sword with the energy of the Full Moon to slash at all enemies in sight.",
        skillTwoDescription:
            "Splits the target in two with a sword as hot as the blazing sun.",
        skillThreeDescription:
            "Focus the soul into a strike that separates the living from the dead. While charging, no attack can push you back.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/79/Skill_Speeding_Sunset.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/79/Skill_Speeding_Sunset.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/b7/Skill_Styx_Crossing.png",
        weapon: "ONE- OR TWO-HANDED SWORD",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2152/190725_dawnwarrior_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/5/5b/ClassArtwork_Dawn_Warrior_%28Cygnus_Returns%2C_Female%29.png",
    },
    {
        name: "Demon Avenger",
        description:
            "Demon Avenger wields a Desperado and relies heavily on Health Points (HP) for damage and skills",
        attribute: "HP",
        skillOne: "Execution",
        skillTwo: "Nether Shield",
        skillThree: "Infernal Exceed",
        skillOneDescription:
            "Uses the true powers of the Demon to deal a powerful slash, ignoring a portion of enemy defense.",
        skillTwoDescription:
            "Enchants your shield with a tracking spirit, which hits an enemy and then bounces to the next target.",
        skillThreeDescription:
            "Permanently adds a chance for an additional attack after the Exceed attack.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/ef/Skill_Exceed_Execution.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e5/Skill_Nether_Shield.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/12/Skill_Infernal_Exceed.png",
        weapon: "DESPERADO (SPECIAL SWORD)",
        background:
            "https://nxcache.nexon.net/umbraco/2549/demonavenger_header_web.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Resistance_DemonAvenger.png",
    },
    {
        name: "Demon Slayer",
        description:
            "The Demon Slayer job branch can choose to wield a One-Handed Axe or a One-Handed Blunt Weapon. Demon Slayer possesses many area of effect skills, allowing him to effectively wipe out mobs with ease.",
        attribute: "STR",
        skillOne: "Demon Impact",
        skillTwo: "Demon Lash",
        skillThree: "Cerberus Chomp",
        skillOneDescription:
            "Call forth your inner demon to deal critical damage to multiple enemies in front of you.",
        skillTwoDescription:
            "Mash the attack key to whip enemies in front of you 4 times with dark power. Hits return some Fury to you.",
        skillThreeDescription:
            "Utilize the power of the nether world to damage enemies and rob them of their souls.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/f/fc/Skill_Demon_Impact.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e6/Skill_Demon_Lash.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/ce/Skill_Cerberus_Chomp.png",
        weapon: "ONE-HANDED AXE OR BLUNT WEAPON",
        background:
            "https://nxcache.nexon.net/umbraco/2550/demonslayer_header_web.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/c/c9/ClassArtwork_Demon_Slayer_%28Legends%29.png",
    },
    {
        name: "Hayato",
        description:
            "Armed with a Katana and a Kodachi, Hayato uses quick, flashy combos to slice through his enemies.",
        attribute: "STR",
        skillOne: "Rai Blade Flash",
        skillTwo: "Shinsoku",
        skillThree: "Rai Sanrenzen",
        skillOneDescription:
            "Draws your sword in a blink of the eye to deal devastating damage to 1 enemy.",
        skillTwoDescription:
            "Focuses your fury and skill to attack a single enemy.",
        skillThreeDescription:
            "Slashes 8 enemies at once with 3 consecutive blows. Has a chance to deal additional damage.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/12/Skill_Rai_Blade_Flash.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/b7/Skill_Shinsoku.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/7e/Skill_Rai_Sanrenzan.png",
        weapon: "KATANA",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2157/190725_hayato_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/8/8e/ClassArtwork_Hayato_%282015%2C_Male%29.png",
    },
    {
        name: "Kaiser",
        description:
            " Wielding a Two-Handed Sword and a Dragon Essence, Kaiser uses the power of the dragon to decimate the battlefield.",
        attribute: "STR",
        skillOne: "Gigas Wave",
        skillTwo: "Wing Beat",
        skillThree: "Tempest Blades",
        skillOneDescription:
            "Devastates enemies with a powerful slash of your blade. All enemies, including bosses, suffer the Slow effect when hit.",
        skillTwoDescription:
            "Creates a whirlwind that attacks enemies until certain targets are defeated. Dissipates after a short distance or time.",
        skillThreeDescription: "Summons 3 swords to track and attack monsters.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/9/96/Skill_Gigas_Wave.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/8/87/Skill_Wing_Beat.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e3/Skill_Tempest_Blades.png",
        weapon: "TWO-HANDED SWORD",
        background:
            "https://nxcache.nexon.net/umbraco/2542/kaiser_header_web.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/1/12/ClassArtwork_Kaiser.png",
    },
    {
        name: "Mihile",
        description:
            "Mihile's is known as the Soul Shield, and able to provide huge offensive buffs and protection for their allies.",
        attribute: "STR",
        skillOne: "Radiant Cross",
        skillTwo: "Four-Point Assault",
        skillThree: "Charging Light",
        skillOneDescription:
            "Slice ahead with a cross-shaped light. Reduces Enemy Accuracy with a Blind effect.",
        skillTwoDescription:
            "Unleash soul blades with a chance to blind enemies. The final hit will always be a Critical Hit.",
        skillThreeDescription:
            "Summons the mighty Knight of Light to carry out a deadly charge.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/9/9e/Skill_Radiant_Cross.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/bb/Skill_Four-Point_Assault.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/b5/Skill_Charging_Light.png",
        weapon: "ONE-HANDED SWORD",
        background:
            "https://nxcache.nexon.net/umbraco/2534/mihile_header_web.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/6/69/ClassArtwork_Mihile_%28Awake%29.png",
    },
    {
        name: "Hero",
        description:
            "Heroes specialize in 1-2 handed swords and axes and/or shields. They utilize combo strikes to create powerful blows to shake the enemy. ",
        attribute: "STR",
        skillOne: "Raging Blow",
        skillTwo: "Rising Rage",
        skillThree: "Puncture",
        skillOneDescription:
            "Deal consecutive blows to multiple enemies in front of you",
        skillTwoDescription:
            "Suppresses your inner rage into a powerful energy sphere.",
        skillThreeDescription:
            "Leave a wound on your enemy that deals continuous damage for a short time. While taking continuous damage, the enemy will also take more critical damage. Also permanently increases Shout's damage.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/7f/Skill_Raging_Blow.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/2a/Skill_Rising_Rage.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/7d/Skill_Puncture.png",
        weapon: "ONE- OR TWO-HANDED SWORD OR AXE",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2181/190725_warrior_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/f/f7/ClassArtwork_Warrior_%28Awake%2C_Male%29.png",
    },
    {
        name: "Paladin",
        description:
            "The Paladin job branch can choose to wield a Sword or Mace and uses the power of fire, ice, lightning, and holy elements to scar the battlefield.",
        attribute: "STR",
        skillOne: "Blast",
        skillTwo: "Lightning Charge",
        skillThree: "Heaven's Hammer",
        skillOneDescription:
            "Deal a powerful blow against a single opponent.",
        skillTwoDescription:
            "Instantly grants lightning attribute to your weapon to deal lightning damage to enemies and stun them.",
        skillThreeDescription:
            "Slam a large hammer into the ground, dealing damage to multiple enemies.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/cb/Skill_Blast.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/4/44/Skill_Lightning_Charge.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/bd/Skill_Heaven%27s_Hammer.png",
        weapon: "ONE- OR TWO-HANDED SWORD OR BLUNT WEAPON",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2181/190725_warrior_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/1/15/ClassArtwork_Paladin_%28MapleStory_M%29.png",
    },
    {
        name: "Dark Knight",
        description:
            "Dark Knights are warriors who utilizes Spear or Polearm to attack their enemy. They use a variety of supportive buffs to support their party members, as well as their own.",
        attribute: "STR",
        skillOne: "Dark Impale",
        skillTwo: "Gungir's Descent",
        skillThree: "Nightshade Explosion",
        skillOneDescription:
            "Launch consecutive rapid attacks on multiple enemies.",
        skillTwoDescription:
            "Drops an enormous, mythical spear from the heavens to deal damage. You can deal higher damage with greater Max HP.",
        skillThreeDescription:
            "Releases your inner power to lay waste to nearby enemies.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/34/Skill_Dark_Impale.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/70/Skill_Gungnir%27s_Descent.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/b3/Skill_Nightshade_Explosion.png",
        weapon: "SPEAR OR POLEARM",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2181/190725_warrior_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/3/3a/ClassArtwork_Dark_Knight_%28MapleStory_M%29.png",
    },
    {
        name: "Zero",
        description:
            "Zero's skills are divided into two skill sets: one for Alpha and another for Beta. Zero uses a combination system where certain skills can be used after another combo effect",
        attribute: "STR",
        skillOne: "Pierce Thrust",
        skillTwo: "Spin Cutter",
        skillThree: "Rolling Cross",
        skillOneDescription:
            "Thrusts your Long Sword forward to knock back enemies with the tip. Can be resisted by some monsters.",
        skillTwoDescription: "Attacks enemies with spinning strikes.",
        skillThreeDescription:
            "Spins at very high speed, leaping forward and up, cutting through enemies in your path.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/5/5e/Skill_Piercing_Thrust.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/5/51/Skill_Spin_Cutter.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/23/Skill_Rolling_Cross.png",
        weapon: "LAPIS AND LAZULI (SPECIAL SWORDS)",
        background:
            "https://nxcache.nexon.net/umbraco/2555/zero_header_web.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/9/98/ClassArtwork_Zero.png",
    },
    {
        name: "Bowmaster",
        description:
            "The Bowmaster job branch focuses on shooting arrows up close and afar, inundating their enemies with flurries of arrows",
        attribute: "DEX",
        skillOne: "Hurricane",
        skillTwo: "Arrow Blaster",
        skillThree: "Quiver Cartridge",
        skillOneDescription:
            "Launches arrows at tremendous speed like a catastrophic rainstorm. Holding the skill key will fire arrows continuously.",
        skillTwoDescription:
            "Shoots an arrow at a high angle. If you press and hold the skill key, it will fire continuously.",
        skillThreeDescription:
            "Lets you use 3 different special arrowheads. The quiver fills up again when you use all 3. Press the skill key to change the current arrowhead.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/18/Skill_Hurricane.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/f/f5/Skill_Arrow_Blaster.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/6/6d/Skill_Quiver_Cartridge.png",
        weapon: "BOW",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2151/190725_bowman_jobclass.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_ExplorerAdventurer_Archer.png",
    },
    {
        name: "Marksman",
        description:
            "The Marksman job branch specializes in Crossbows as primary weapons and Bow Thimbles as secondary weapons. Like the Bowmaster, Marksmen fires arrows from afar, but with a bigger emphasis on firing from a distance, as their skills' damage are increased the further away they are from the monsters.",
        attribute: "DEX",
        skillOne: "Snipe",
        skillTwo: "Piercing Arrow",
        skillThree: "High Speed Shot",
        skillOneDescription:
            "Fires a carefully-aimed shot to deal severe damage.",
        skillTwoDescription:
            "Fires an arrow. Its effect increases with each enemy it hits.",
        skillThreeDescription:
            "Fires off a focused shot that pierces all enemies within range.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/8/88/Skill_Snipe.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/8/84/Skill_Piercing_Arrow.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e8/Skill_High_Speed_Shot.png",
        weapon: "CROSSBOW",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2151/190725_bowman_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/1/1b/ClassArtwork_Marksman_%28MapleStory_M%29.png",
    },
    {
        name: "Wild Hunter",
        description:
            "A member of the Resistance that fires a volley of arrows from the back of a tamed Jaguar to overpower foes with relentless attacks",
        attribute: "DEX",
        skillOne: "Wild Arrow Blast",
        skillTwo: "Exploding Arrows",
        skillThree: "Sonic Roar",
        skillOneDescription:
            "Launches arrows at tremendous speed, like a catastrophic rainstorm. Pressing the skill key will fire arrows continuously.",
        skillTwoDescription:
            "Calls on the essence of the Resistance. Can only be used when mounted on a Jaguar.",
        skillThreeDescription:
            "The Jaguar roars viciously. The roar creates a shockwave that damages multiple enemies.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/71/Skill_Wild_Arrow_Blast.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/9/9f/Skill_Exploding_Arrows.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/a/a8/Skill_Sonic_Roar.png",
        weapon: "CROSSBOW AND JAGUAR",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2183/msw-4243-190725-update-character-header-wild-hunter.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Resistance_WildHunter2.jpg",
    },
    {
        name: "Wind Archer",
        description:
            "Blessed by the spirit of wind, they use nature and wind based attacks, utilizing a Bow as a primary weapon and a Jewel as a secondary weapon. Wind Archers, as Bowmen, use the bow and arrows to fight enemies",
        attribute: "DEX",
        skillOne: "Song of Heaven",
        skillTwo: "Trifling Wind",
        skillThree: "Storm Bringer",
        skillOneDescription: "Play the song of heavens to sweep away enemies.",
        skillTwoDescription:
            "Summons the Spirit of Wind to confuse nearby enemies.",
        skillThreeDescription: "Calls down a torrential storm on your enemies.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/11/Skill_Song_of_Heaven.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/0/0d/Skill_Trifling_Wind_I.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/27/Skill_Storm_Bringer.png",
        weapon: "BOW",
        background:
            "https://nxcache.nexon.net/umbraco/2523/windarcher_header_web.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/7/75/ClassArtwork_Wind_Archer_%28Cygnus_Returns%2C_Male%29.png",
    },
    {
        name: "Mercedes",
        description:
            "They are Elf who are experts in wielding dual crossbows. They are very agile, moving across large maps and multiple platforms without any hassle",
        attribute: "DEX",
        skillOne: "Ishtar's Ring",
        skillTwo: "Stunning Strikes",
        skillThree: "Lightning Edege",
        skillOneDescription:
            "Borrows the power of Ishtar, a legendary weapon, to rapidly fire arrows at enemies ahead of you. Hold down the skill key to fire arrows continuously. In addition, damage from Unicorn Spikes will be increased permanently.",
        skillTwoDescription:
            "Quickly fire multiple arrows at enemies in front of you. Enemies who are hit have a chance to be stunned. Any enemies who are already stunned will take additional damage. Can be used in the air and linked with Rising Rush, Unicorn Spike, Spikes Royale, Lightning Edge, or Wrath of Enlil.",
        skillThreeDescription:
            "Dashes through multiple enemies. Ignores collision with bosses while attacking. Also increases Gust Dive damage. Can be linked with Rising Rush, Stunning Strikes, Unicorn Spike, Spikes Royale, or Wrath of Enlil.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/70/Skill_Ishtar%27s_Ring.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/33/Skill_Stunning_Strikes.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/f/fb/Skill_Lightning_Edge.png",
        weapon: "DUAL BOWGUNS",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2169/190725_mercedes_jobclass.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Legends_Mercedes_V2.jpg",
    },
    {
        name: "Pathfinder",
        description:
            "Using Ancient Bows as primary weapons and Relics as secondary weapons, Pathfinder receives power from the Relic to gain different attacks that can boost each other.",
        attribute: "DEX",
        skillOne: "Cardinal Blast",
        skillTwo: "Cardinal Deluge",
        skillThree: "Cardinal Torrent",
        skillOneDescription:
            "An archery technique that launches an explosive arrow of condensed energy that flies straight ahead. The arrow will detonate when it hits an enemy or reaches its maximum range.",
        skillTwoDescription:
            "An archery technique that zeroes in on targets within range. The damage from the guided shots fired with this skill cannot be reflected.",
        skillThreeDescription:
            "An archery technique used to achieve high mobility. Release Swiftstrike Energy stored in your weapon as a short burst of speed that propels you to your destination, then swing your weapon to attack nearby enemies.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/5/56/Skill_Cardinal_Burst.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/8/8b/Skill_Cardinal_Deluge.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/9/96/Skill_Cardinal_Torrent.png",
        weapon: "ANCIENT BOW",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/1267/190531_pathfinder_job_page_bg.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Adventurer_Pathfinder.jpg",
    },
    {
        name: "Angelic Buster",
        description:
            "She is equipped with a Soul Shooter in her main slot and a Soul Ring in her shield slot. The Soul Shooter is a dragon-shaped arm cannon that the Angelic Buster uses. It can target multiple enemies and blast them with ease",
        attribute: "DEX",
        skillOne: "Soul Seeker",
        skillTwo: "Trinity",
        skillThree: "Supernova",
        skillOneDescription:
            "Summons the essence of Eskalade’s power and sends it flying at enemies. Deals damage continuously until the max is reached or until it disappears.",
        skillTwoDescription:
            "Condenses Eskalade's power to attack one enemy continuously.",
        skillThreeDescription:
            "Eskalade's power channels the forces of the universe, blocking damage from enemy's attack reflection.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/2/2c/Skill_Soul_Seeker.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/8/8a/Skill_Trinity.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/0/0b/Skill_Supreme_Supernova.png",
        weapon: "SOUL SHOOTER",
        background:
            "https://nxcache.nexon.net/umbraco/2545/angelicbuster_header_web.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/6/6b/ClassArtwork_Angelic_Buster_%28Tempest%29.png",
    },
    {
        name: "Cannoneer",
        description:
            "Using Hand Cannons as primary weapons and Powder Kegs as secondary weapons, Cannoneers have slow but strong attacks which revolve around firing cannonballs",
        attribute: "STR",
        skillOne: "Cannon Barrage",
        skillTwo: "Cannon Bazooka",
        skillThree: "Rolling Rainbow",
        skillOneDescription:
            "Fires multiple cannonballs at once, focusing on one enemy ahead of you.",
        skillTwoDescription:
            "Fires a powerful cannon full of compressed gunpowder to burn enemies ahead of you to the ground.",
        skillThreeDescription:
            "Orders the Cannoneer's reliable assistants to summon three cannons and mercilessly roast enemies ahead of you.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/1/1d/Skill_Cannon_Barrage.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/8/85/Skill_Cannon_Bazooka.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/a/ac/Skill_Rolling_Rainbow.png",
        weapon: "HAND CANNON",
        background:
            "https://nxcache.nexon.net/umbraco/2546/cannoneer_header_web.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/6/6b/ClassArtwork_Cannoneer_%28Legends%29.png",
    },
    {
        name: "Jett",
        description:
            "Jett uses a Gun as their primary weapon and a Fist as a secondary weapon. With high dexterity and mobility, Jett uses guns and the extraterrestrial technology to obliterate those who stand against her",
        attribute: "DEX",
        skillOne: "Starline One",
        skillTwo: "Starforce Salvo",
        skillThree: "Singularity Shock",
        skillOneDescription:
            "Use the attack key to deal quick hits to nearby enemies.",
        skillTwoDescription:
            "Fires a hail of energy bolts. Hold down the skill key to keep shooting. You can move left and right while attacking.",
        skillThreeDescription:
            "Focus the Core's power into a single point of pure destruction that explodes outward, knocking enemies back and inflicting additional damage after the attack ends.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/0/0d/Skill_Starline_One.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/0/09/Skill_Starforce_Salvo.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/e/e9/Skill_Singularity_Shock.png",
        weapon: "GUN",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2159/190725_jett_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/0/0f/ClassArtwork_Jett_%28Female%2C_Renegades%29.png",
    },
    {
        name: "Mechanic",
        description:
            "Mechanic is a Pirate job which uses guns for weapons, albeit without the bullets. Mechanics have fast-paced combat advantages, with 2 modes: Humanoid Mech, which serves to fight multiple monsters at a time, and Tank Mech, which serves to fight monsters one-on-one",
        attribute: "DEX",
        skillOne: "Homing Beacon",
        skillTwo: "Massive Fire: SPLASH-F",
        skillThree: "Distortion Bomb",
        skillOneDescription:
            "Launches a Homing Beacon to attack multiple enemies. The beacon is programmed to chase the targeted enemy.",
        skillTwoDescription:
            "Fires off all Humanoid Mech ammo at once to scatter your attacks across multiple enemies. You can use this skill key in Tank mode to use its built-in weapons.",
        skillThreeDescription:
            "Detonates a special Resistance bomb to create a distortion field.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/3b/Skill_Homing_Beacon.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/d/d7/Skill_Heavy_Salvo_Plus.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/8/84/Skill_Distortion_Bomb.png",
        weapon: "GUN AND MECH",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2167/msw-4244-190725-mechanic-class-page-template.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Resistance_Mechanic2.jpg",
    },
    {
        name: "Buccaneer",
        description:
            "The Buccaneer job branch is centered around punching enemies up close, as well as using specific ranged attacks from afar. They specialize in Knuckles as primary weapons and Wrist Bands as secondary weapons",
        attribute: "STR",
        skillOne: "Buccaneer Blast",
        skillTwo: "Nautilus Strike",
        skillThree: "Spiral Assault",
        skillOneDescription:
            "Focuses your energy into powerful bolts that shoot at enemies. When fully charged, Double Blast does more damage and travels further.",
        skillTwoDescription:
            "Orders the Nautilus to attack multiple enemies at once. Nautilus is unaffected by attack reflection and after use, deals additional attacks for a set time when attacking with physical skills.",
        skillThreeDescription:
            "Summons superhuman strength to charge enemies and knock them back. Links with Corkscrew Blow. This skill can be used while using other skills and moving forward.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/b2/Skill_Buccaneer_Blast.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/c9/Skill_Nautilus_Strike.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/9/96/Skill_Spiral_Assault.png",
        weapon: "KNUCKLE",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2173/190725_pirate_jobclass.jpg",
        sprite:
            "https://static.wikia.nocookie.net/maplestory/images/1/1b/ClassArtwork_Buccaneer_%28MapleStory_M%29.png",
    },
    {
        name: "Corsair",
        description:
            "The Corsair job branch Bullets alongside their primary weapon, Guns, and Far Sights as secondary weapons. They fire bullets at enemies from afar and can summon crewmates to aid in battle.",
        attribute: "DEX",
        skillOne: "Rapid Fire",
        skillTwo: "Broadside",
        skillThree: "Eight-Legs Easton",
        skillOneDescription:
            "Fires a hail of energy bolts. Hold down the skill key to keep shooting. You can move left and right while attacking.",
        skillTwoDescription:
            "Summons a battleship with all of the Nautilus crewmen on board. The battleship's cannon attack is unaffected by attack reflection.",
        skillThreeDescription:
            "Summons the dread pirate Eight-Legs Easton, a giant, trigger-happy octopus that fires a hefty barrage of bullets.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/79/Skill_Rapid_Fire.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/6/65/Skill_Broadside.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/37/Skill_Eight-Legs_Easton.png",
        weapon: "GUN",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2173/190725_pirate_jobclass.jpg",
        sprite:
            "https://ayumilove.net/files/games/maplestory/job_selection/MapleStory_Explorer_Pirate_2.png",
    },
    {
        name: "Shade",
        description:
            "Using the power of spirits, Shade mixes melee and semi-long range attacks along with combos that are powerful and can debuff, but are often quite slow",
        attribute: "STR",
        skillOne: "Spirit Claw",
        skillTwo: "Bomb Punch",
        skillThree: "Fox Spirits",
        skillOneDescription:
            "Summons a wind spirit to claw at multiple enemies.",
        skillTwoDescription:
            "Summons a nature spirit to attack multiple enemies in front. Can be used 4 times in a row.",
        skillThreeDescription:
            "Fox spirits may be summoned with each of your attacks. The foxes chase down and attack enemies.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/c/c8/Skill_Spirit_Claw.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/ba/Skill_Bomb_Punch.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/5/5a/Skill_Fox_Spirits.png",
        weapon: "KNUCKLE",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2175/190725_shade_jobclass.jpg",
        sprite: "",
    },
    {
        name: "Thunder Breaker",
        description:
            "They use knuckles as their main weapons and can fight at close to fairly long range, using lightning and thunder based techniques. Thunder Breakers have a specific system that allows them to chain almost all of their techniques together in successive manuevers.",
        attribute: "STR",
        skillOne: "Shark Sweep",
        skillTwo: "Annihilate",
        skillThree: "Gale",
        skillOneDescription:
            "Tap the power of sharks to sweep up nearby mobs.",
        skillTwoDescription:
            "Summon a massive charge of electricity to blast enemies in front of you.",
        skillThreeDescription:
            "Unleashes the power gained from using link skills at once.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/b2/Skill_Shark_Sweep.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/3/3f/Skill_Annihilate.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/b/b4/Skill_Gale.png",
        weapon: "KNUCKLE",
        background:
            "https://nxcache.nexon.net/cms/2019/Q3/2179/msw-4241-190725-class-page.jpg",
        sprite: "",
    },
    {
        name: "Ark",
        description:
            "Specializes in knuckle weapon to attack enemies in close range. Ark can switch between two modes: Spectral Form where he gains monstrous power from the abyss and the Flora Form where he incorporate traditional High Flora combat techniques with magic.",
        attribute: "STR",
        skillOne: "Basic Charge Drive",
        skillTwo: "Ominous Nightmare",
        skillThree: "Impending Death",
        skillOneDescription:
            "Uses magically-enhanced strength to attack your enemies.",
        skillTwoDescription:
            "Inflict extreme lacerations on enemies using your specter hand.",
        skillThreeDescription:
            "Unleashes Abyssal Aura when attacking with skills in Specter State.",
        skillOneImage:
            "https://static.wikia.nocookie.net/maplestory/images/a/a7/Skill_Basic_Charge_Drive.png",
        skillTwoImage:
            "https://static.wikia.nocookie.net/maplestory/images/7/7c/Skill_Ominous_Nightmare.png",
        skillThreeImage:
            "https://static.wikia.nocookie.net/maplestory/images/9/9e/Skill_Impending_Death.png",
        weapon: "KNUCKLES",
        background:
            "https://nxcache.nexon.net/cms/2018/2559/180628arkcnjdsgf674.jpg",
        sprite: "",
    },
];
