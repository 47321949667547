import React from 'react';
import IntroBanner from '../components/intro-banner/IntroBanner';

const Landing = () => {

    return (
        <div style={{ backgroundColor: '#F3F0D8', height: "100%"}}>
            <IntroBanner></IntroBanner>
        </div>
    );
};

export default Landing;