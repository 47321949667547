import React from "react";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import { toTitleCaseFromKebabCase } from "../helpers/toTitleCaseFromKebabCase";
import { jobClasses } from "../assets/jobClasses";
import CharacterAbility from "../components/CharacterAbility";
import { toUrlSafe } from "../helpers/toUrlSafe";
const requestImageFile = require.context("../assets/images/characters", true);

const StyledResults = styled.div``;

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
//   }

const Results = (props) => {
    // let query = useQuery();
    let location = useLocation();

    let {
        name,
        description,
        skillOne,
        skillOneDescription,
        skillOneImage,
        skillTwo,
        skillTwoDescription,
        skillTwoImage,
        skillThree,
        skillThreeDescription,
        skillThreeImage,
    } = jobClasses.filter((job) => {
        return (
            job.name ===
            toTitleCaseFromKebabCase(location.pathname.substring(9))
        );
    })[0];

    let returnStyle = {
        border: "2px solid #497B1E",
        borderRadius: "5px",
        padding: "8px 20px",
        fontSize: "13px",
        color: "#497B1E",
        // fontWeight: "bold",
        backgroundColor: "transparent",
    };

    return (
        <StyledResults>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-12 col-sm-3">
                        <Link to="/survey">
                            <button style={returnStyle}>
                                <i className="fas fa-chevron-left pr-2"></i>
                                Go Back
                            </button>
                        </Link>
                    </div>
                </div>
                <div className="row justify-content-center mt-5">
                    <div className="col-12 col-sm-auto">
                        <img
                            src={
                                requestImageFile(
                                    `./${location.pathname
                                        .substring(9)
                                        .replace("/", "-")}.png`
                                ).default
                            }
                            alt=""
                            width="121"
                        />
                    </div>
                    <div className="col-12 col-sm-3">
                        <h2
                            style={{
                                color: "#363340",
                                fontSize: "24px",
                                fontWeight: "bold",
                            }}
                        >
                            {name}
                        </h2>
                        <p
                            className="class-description"
                            style={{ fontSize: "13px", color: "#635D58" }}
                        >
                            {description}
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-12 col-md-3">
                        <p
                            className="mb-3"
                            style={{
                                fontSize: "24px",
                                fontWeight: "bold",
                                color: "#363340",
                            }}
                        >
                            Top Abilities
                        </p>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3"></div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-3">
                        <CharacterAbility
                            name={skillOne}
                            description={skillOneDescription}
                            imageUrl={skillOneImage}
                        ></CharacterAbility>
                    </div>
                    <div className="col-12 col-md-3">
                        <CharacterAbility
                            name={skillTwo}
                            description={skillTwoDescription}
                            imageUrl={skillTwoImage}
                        ></CharacterAbility>
                    </div>
                    <div className="col-12 col-md-3">
                        <CharacterAbility
                            name={skillThree}
                            description={skillThreeDescription}
                            imageUrl={skillThreeImage}
                        ></CharacterAbility>
                    </div>
                </div>
                <div className="row justify-content-center mb-4 mt-2">
                    <div
                        className="col-11 col-sm-9"
                        style={{ borderTop: "2px solid rgba(99,93,88,.2)" }}
                    ></div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-9">
                        <p
                            className="mb-3"
                            style={{ fontWeight: "bold", fontSize: "24px" }}
                        >
                            Noteworthy Picks
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-9">
                        {Object.values(location.state)
                            .filter((char) => char.NAME !== name)
                            .map((char) => {
                                return (
                                    <div key={char.NAME} className="d-inline-block mr-4 mb-5 mb-sm-0">
                                        <p style={{fontWeight: 'bold', textDecoration: 'underline'}}>{char.NAME}</p>
                                        <Link
                                            className="d-block"
                                            style={{minHeight: '141px'}}
                                            to={{
                                                pathname: `/results/${toUrlSafe(
                                                    char.NAME
                                                )}`,
                                                state: { ...location.state },
                                            }}
                                        >
                                            <img
                                                 src={
                                                    requestImageFile(
                                                        `./${toUrlSafe(
                                                            char.NAME
                                                        )}.png`
                                                    ).default
                                                }
                                                width="121"
                                                alt=""
                                            />
                                        </Link>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </StyledResults>
    );
};

export default Results;
