import React from "react";
import styled from "styled-components";
import { toTitleCaseFromSnakeCase } from "../../helpers/toTitleCase";

const Card = styled.div`
    color: black;
    /* min-width: 150px; */
    margin-bottom: 0px;
    max-width: 150px;
    height: fit-content;
    text-align: center;
    /* height: 100%; */
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: ${(props) => (props.isDragging ? "0px" : "5px")};
    border-bottom-right-radius: ${(props) =>
        props.isDragging ? "0px" : "5px"};

    @media (min-width: 992px) {
        min-width: 150px;
        margin-bottom: 0px;
    }

    .name {
        white-space: nowrap;
        padding: 20px;
        line-height: 1;
        background-color: white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: ${(props) =>
            props.isDragging ? "0px" : "5px"};
        border-bottom-right-radius: ${(props) =>
            props.isDragging ? "0px" : "5px"};
        font-weight: bold;
        color: #363340;

        @media (min-width: 992px) {
            padding: 30px 0px;
        }
    }

    .attribute__description {
        text-align: left;
        color: #363340;
        font-size: 14px;
        display: block;
        line-height: 16px;
        padding: 15px 10px;
        display: none;
        border-bottom-left-radius: ${(props) =>
            props.isDragging ? "5px" : "0px"};
        border-bottom-right-radius: ${(props) =>
            props.isDragging ? "5px" : "0px"};
    }

    .attribute__description {
        /* display: block */
        display: ${(props) => (props.isDragging ? "block" : "none")};
        background-color: #f2f2f2;
        width: 100%;
    }

    &:hover {
    }
`;

const AttributeCard = React.forwardRef((props, ref) => {
    const { color, name, description, assigned, ...leftoverProps } = props;
    return (
        <Card ref={ref} color={color} assigned={assigned} {...leftoverProps}>
            <p className="name">{toTitleCaseFromSnakeCase(name)}</p>
            <p className="attribute__description">{description}</p>
        </Card>
    );
});

export default AttributeCard;
