import React from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";

const StyledFooter = styled.footer`
    /* background-color: #497B1E; */
    color: "#363340";
    /* position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%; */
    display: flex;
    justify-content: center;
    padding: 12px 0px;

    .progress-block {
        font-size: 18px;
        font-weight: bold;
        .progress-title {
            color: #363340;
            font-size: 14px;
            font-weight: normal;
        }
    }

    button {
        padding: 6px 25px;
        border-radius: 5px;
        /* font-weight: bold; */

        &.button-reset {
            background-color: transparent;
            border: 2px solid #497b1e;
            color: #497b1e;
        }

        &.button-calculate {
            background-color: #497b1e;
            border: none;
            color: white;
        }
    }
`;

const StyledSurveyBanner = styled.div`
    text-align: left;
    margin-top: 45px;
    h1 {
        font-weight: bold;
        font-size: 36px;
    }

    span {
        color: #497b1e;
        font-weight: bold;
    }

    p {
        color: #635d58;
    }
`;
const SurveyBanner = (props) => {
    return (
        <StyledSurveyBanner>
            <h1>Maple Tier Tool</h1>
            <div className="container-fluid px-0">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <p className="mt-2">
                            <span>Hover</span> over attributes for a
                            description.
                        </p>
                        <p>
                            <span>Drag and Drop</span> the attributes according
                            to your designated play style preferences below
                        </p>
                    </div>
                    <div className="col-auto">
                        <StyledFooter>
                            <div className="progress-block mr-4 d-none d-sm-block">
                                <p className="progress-title">PROGRESS</p>
                                <p>{props.progress}/7</p>
                            </div>
                            {!props.isSpinning && (
                                <button
                                    className="button-reset mr-3"
                                    onClick={props.resetClickHandler}
                                >
                                    Reset
                                </button>
                            )}
                            {!props.isSpinning && (
                                <button
                                    className="button-calculate"
                                    onClick={props.calculateClickHandler}
                                >
                                    Calculate
                                </button>
                            )}
                            <Loader
                                type="TailSpin"
                                color="#00BFFF"
                                height={35}
                                width={35}
                                visible={props.isSpinning}
                            />
                        </StyledFooter>
                    </div>
                </div>
            </div>
        </StyledSurveyBanner>
    );
};

export default SurveyBanner;
