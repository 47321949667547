import Nav from "../components/nav/Nav";
import Router from "../components/Router";
import Footer from '../components/footer/Footer';

function App() {
    return (
        <div className="App" style={{ paddingBottom: "120px", minHeight: "100vh",height: "100%", backgroundColor: "#F3F0D8", position: 'relative' }}>
            <Nav></Nav>
            <Router></Router>
            <Footer></Footer>
        </div>
    );
}

export default App;
