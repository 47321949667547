import React from 'react';
import styled from 'styled-components';
import { toTitleCaseFromSnakeCase } from '../../helpers/toTitleCase';

const StyledRankCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 27px;
    text-align: center;
    border-radius: 3px; 
    /* font-weight: bold; */
    height: 100%;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    /* margin-bottom: 10px; */

    @media (min-width: 992px){
        padding: 40px 0px;
    }

    p{
        /* font-weight: bold; */
    }
`;
const RankCard = props => {
    return (
        <StyledRankCard color={props.color} backgroundColor={props.backgroundColor}>
            <p>{toTitleCaseFromSnakeCase(props.name)}</p>
        </StyledRankCard>
    );
};

export default RankCard;