import React, { Component } from "react";
import { POINTS } from "../../assets/appStrings";
import RankCard from "./RankCard";
import { Droppable, Draggable } from "react-beautiful-dnd";
import AttributeCard from "../attribute-card/AttributeCard";
import styled from "styled-components";

const OverflowableContainer = styled.div`
    overflow: scroll;

    @media ( min-width: 992px ){
        overflow: hidden;
    }
`;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#DECB9C" : "#EBDFC2",
    padding: '12px 8px',
    minWidth: '60vw',
    width: "100%",
    height: "100%",
    marginBottom: '-10px'
});

class Tierlist extends Component {
    componentDidMount() {
        console.log("Props:", this.props);
    }

    state = {
        ranks: [...POINTS],
        essential: [],
        important: [],
        useful: [],
        optional: [],
        undesirable: [],
    };

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable1: "essential",
        droppable2: "important",
        droppable3: "useful",
        droppable4: "optional",
        droppable5: "undesirable",
    };

    /**
     * Takes a list's droppable id and returns the list in the state
     * which corresponds to the id.
     */
    getList = (id) => this.state[this.id2List[id]];

    render() {
        Array.from(
            this.state.ranks.sort((a, b) => {
                return a.index > b.index ? 1 : -1;
            })
        );

        return (
            // <OverflowableContainer className="container px-sm-0" style={{ maxWidth: "1325px"}}>
            <OverflowableContainer className="container px-sm-0">
                {Array.from(
                    this.state.ranks.sort((a, b) => {
                        return a.index > b.index ? 1 : -1;
                    })
                ).map((rank, index) => (
                    <div className="row mt-3 flex-nowrap flex-sm-wrap" key={`${rank}-${index}`}>
                        {/* Rank Column */}
                        <div className="col-5 col-sm-2">
                            <RankCard {...rank}></RankCard>
                        </div>

                        {/* Droppable Rank Column */}
                        <div className="col-auto col-sm-10 pl-0">
                            {/* 1. Create a column with the individual droppableId's for each rank */}
                            <Droppable
                                droppableId={`droppable${index + 1}`}
                                direction="horizontal"
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getListStyle(
                                            snapshot.isDraggingOver
                                        )}
                                        className="d-flex align-items-center"
                                    >
                                        {/* 2. Iterate through rank list and display any attributes associated with it */}
                                        {this.props[
                                            rank.name.toLowerCase()
                                        ].map((attribute, index) => (
                                            <Draggable
                                                key={attribute.name}
                                                draggableId={attribute.name}
                                                index={index}
                                            >
                                                {(provided, snapshot) => (
                                                    <AttributeCard
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided
                                                                .draggableProps
                                                                .style
                                                        )}
                                                        isDragging={
                                                            snapshot.isDragging
                                                        }
                                                        name={attribute.name}
                                                        description={
                                                            attribute.description
                                                        }
                                                        className="mr-2"
                                                        color={attribute.color}
                                                        assigned={true}
                                                    ></AttributeCard>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                ))}

                <br />
            </OverflowableContainer>
        );
    }
}

export default Tierlist;
// export default React.forwardRef((props, ref) => (
//     <Tierlist innerRef={ref} {...props}></Tierlist>
// ))
