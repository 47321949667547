import React, { Component } from "react";
import { stack as Menu } from "react-burger-menu";
import "./MobileNav.scss";

class MobileNav extends Component {
    showSettings(event) {
        event.preventDefault();
    }

    render() {
        return (
            <Menu
                right
                customBurgerIcon={<img src="menu-button.svg" alt="Open mobile navigation" />}
                customCrossIcon={<img src="menu-cross.svg" alt="Close mobile navigation" />}
                burgerButtonClassName={"d-block d-md-none"}
                className={"d-block d-md-none"}
            >
                <a alt="Link to Home" href="/" id="home" className="menu-item">
                    Home
                </a>
                <a alt="Link to Picker" href="/picker" id="picker" className="menu-item">
                    Picker
                </a>
                <a alt="Link to Classes" href="/classes" id="classes" className="menu-item">
                    Classes
                </a>
            </Menu>
        );
    }
}

export default MobileNav;
