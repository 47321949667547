import React from "react";
import { Switch, Route } from "react-router-dom";
import Survey from "../containers/Survey";
import Results from "../containers/Results";
import Landing from '../containers/Landing';

const Router = () => {
    return (
        <Switch>
            <Route exact path="/" component={Landing}></Route>
            <Route path="/survey" component={Survey}></Route>
            <Route path="/results" component={Results}></Route>
        </Switch>
    );
};

export default Router;
