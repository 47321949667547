import React from "react";
import styled from "styled-components";

const StyledAbility = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto;
    grid-row-gap: 7px;
    grid-template-areas: 
        "image"
        "name"
        "description";
    
    img{
        grid-area: image
    }

    p.name{
        grid-area: name
    }

    p.description{
        grid-area: description;
    }
`;

const CharacterAbility = ({ name, description, imageUrl }) => {
    return (
        <StyledAbility className="mt-2 pb-3">
            <img src={imageUrl} alt="" width="50" />
            <p className="name" style={{fontSize: "14px", fontWeight: "bold", color: "#635D58"}}>{name}</p>
            <p className="description" style={{fontSize: "14px", color: "#635D58"}}>{description}</p>
        </StyledAbility>
    );
};

export default CharacterAbility;
