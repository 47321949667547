import React from "react";
import styled from "styled-components";
import logo from "../../assets/images/scardor-logo.png";
import MobileNav from "./mobile-nav/MobileNav";
import { Link } from "react-router-dom";

const NavLinks = styled.ul`
    display: flex;
    list-style: none;
    margin-bottom: 0px;
    align-items: center;

    li {
        margin-right: 1rem;

        &:last-child {
            margin-right: 0rem;
        }
    }
`;

const StyledNavButton = styled.button`
    display: block;
    width: 100%;
    background-color: transparent;
    border-radius: 5px;
    padding: 12px 20px 12px 20px;
    color: #CC1F10;
    font-weight: bold;
    border: none;
    line-height: 21px;
`;

const Nav = () => {

    return (
        <header>
            <MobileNav></MobileNav>
            <div className="container">
                <div className="row no-gutters justify-content-between align-items-center">
                    <div className="col-auto">
                        <Link to="/">
                            <img
                                src={logo}
                                alt=""
                                width="105px"
                                height="auto"
                            />
                        </Link>
                    </div>

                    <div className="col-auto d-none d-md-block">
                        <NavLinks>
                            <li>
                                <Link to="/survey">
                                    <StyledNavButton>Tier Tool</StyledNavButton>
                                </Link>
                            </li>
                            <li>Classes</li>
                        </NavLinks>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Nav;
